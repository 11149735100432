* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* scrollbar-width: none; firefox */
    transition: 'all .5s ease-in-out';
    &::-webkit-scrollbar {
        width: 8px;
        }
      
        /* Track */
        &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px #151515; 
            border-radius: 10px;
        }
        
        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: grey; 
        }
        
        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: grey; 
        }
}

/* *::-webkit-scrollbar {
    display: none;
} */

body {
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
}

/* body::-webkit-scrollbar {
    display: none;
} */


